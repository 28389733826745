import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Header from '../header';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Home from './home';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { encode } from 'querystring';
import { URLs, logger, WsRequest } from '../../utils';
import logIn from './logIn';
import { EventEmitter } from "events";
import { LanguageServiceHost } from 'typescript';

interface MessagePackage {
  wsEvents: EventEmitter;
  wsRequester: WsRequest;
}

interface AppProps {
  language: string;
 }
 
 interface AppState {
    loginMessage: string;
    redirect: any;
    language: string;
 }
 

class Dashboard extends React.Component<AppProps, AppState> {
  WsClient: W3CWebSocket | null;
  loggedIn: () => boolean;
  wsEvents: EventEmitter;
  wsRequester: WsRequest | null;

    constructor(props: any){
        super(props);
        this.state = {
            loginMessage: "Loading... Please login to continue!",
            redirect: <div />,
            language: props.language
        };
        this.WsClient = null;
        this.wsEvents = new EventEmitter();
        this.loggedIn = () => localStorage.getItem('Authentication') != null;
        this.wsRequester = null;
        this.wsEvents.on('message', (message) => {
          console.log(message);
          this.wsRequester?.request('UPDATE_USER_SETTINGS', {})
        });
    };

    async componentDidMount(){
      if (!this.loggedIn()){
        await logIn(this);
      };
      this.componentDidUpdate();
    };
    async componentDidUpdate(){
      console.log(this.WsClient === null, this.WsClient?.CLOSED && this.loggedIn())
      if ((this.WsClient === null || this.WsClient?.CLOSED) && this.loggedIn()) {
        logger('Websocket is closed, connecting...');
        this.WsClient = new W3CWebSocket(`${URLs.GATEWAY}/?authentication=${localStorage.getItem('Authentication')}`, "echo-protocol");
        this.WsClient.onmessage = (message) => {
          this.wsEvents.emit('message', JSON.parse(message.data.toString()));
        };
        this.WsClient.onclose = () => {
          localStorage.clear();
          NotificationManager.error('Your login session timed out please re-authenticate!');
          this.setState({});
        };
        this.wsRequester = new WsRequest(this.WsClient, this.wsEvents);
        logger('Websocket connected and event hooked!');
      };
      
    };

  render(){
    if (!this.loggedIn()) return (
        <Redirect to='/' />
    );
    return (
      <div>
        <Header dashboard={true} language={this.state.language} />
        <Router>
          <Switch>

              <Route path="/dashboard" exact >
                <Home />
              </Route>

              <Route path="/dashboard/*" exact >
                <WiP />
              </Route>

          </Switch>
        </Router>
      </div>
    );
  };
};

class WiP extends React.Component {
  constructor(props){
    super(props);
    NotificationManager.error('Sorry this is a WIP!');
  };
  render(){
    return <Redirect to="/dashboard" />;
  }
};

export default Dashboard;

