import React from 'react';

class Commands extends React.Component {
  render(){
    return (
<div className="container">
			<table>
				<tbody className="text-white">
					<div className="table-header">
						<h4>General</h4>
					</div>
					<tr>
						<th><span>botinfo</span></th>
						<th className="content">Get information about the bot</th>
					</tr>
					<tr>
						<th><span>catch</span></th>
						<th className="content">Catch a Pokémon</th>
					</tr>
					<tr>
						<th><span>help</span></th>
						<th className="content">Get help (for a command)</th>
					</tr>
					<tr>
						<th><span>hint</span></th>
						<th className="content">Get a hint for the Pokémon name</th>
					</tr>
					<tr>
						<th><span>invite</span></th>
						<th className="content">Get our invite links</th>
					</tr>
					<tr>
						<th><span>profile</span></th>
						<th className="content">Check your profile card</th>
					</tr>
					<tr>
						<th><span>release</span></th>
						<th className="content">Release a Pokémon</th>
					</tr>
					<tr>
						<th><span>select</span></th>
						<th className="content">Select a Pokémon</th>
					</tr>
					<tr>
						<th><span>start</span></th>
						<th className="content">Pick a starter</th>
					</tr>
				</tbody>
			</table>
			<table>
				<tbody className="text-white">
					<div className="table-header">
						<h4>Information</h4>
					</div>
					<tr>
						<th><span>dex</span></th>
						<th className="content">Check pokedex information for a Pokémon</th>
					</tr>
					<tr>
						<th><span>info</span></th>
						<th className="content">Get info about a Pokémon</th>
					</tr>
					<tr>
						<th><span>nickname</span></th>
						<th className="content">Give a Pokémon a nickname</th>
					</tr>
					<tr>
						<th><span>pokedex</span></th>
						<th className="content">View the pokedex and gain credits for catching new species of Pokémon</th>
					</tr>
					<tr>
						<th><span>pokemon</span></th>
						<th className="content">Check all your Pokémon</th>
					</tr>
				</tbody>
			</table>
			<table>
				<tbody className="text-white">
					<div className="table-header">
						<h4>Economy</h4>
					</div>
					<tr>
						<th><span>balance</span></th>
						<th className="content">Check your balance</th>
					</tr>
					<tr>
						<th><span>buy</span></th>
						<th className="content">Buy stones, bracelets, forms &amp; rare candies for your Pokémon</th>
					</tr>
					<tr>
						<th><span>daily</span></th>
						<th className="content">Get a certain amount of credits daily</th>
					</tr>
					<tr>
						<th><span>donate</span></th>
						<th className="content">Donate and get redeems</th>
					</tr>
					<tr>
						<th><span>favorite</span></th>
						<th className="content">Mark / unmark your pokemon as favorite</th>
					</tr>
					<tr>
						<th><span>gamble</span></th>
						<th className="content">Gamble your credits with other players!</th>
					</tr>
					<tr>
						<th><span>hunt</span></th>
						<th className="content">Go hunting & encounter a random pokemon!</th>
					</tr>
					<tr>
						<th><span>leaderboard</span></th>
						<th className="content">Check the leaderboard!</th>
					</tr>
					<tr>
						<th><span>quests</span></th>
						<th className="content">Complete quests to get rewards!</th>
					</tr>
					<tr>
						<th><span>redeem</span></th>
						<th className="content">See how much redeems you have</th>
					</tr>
					<tr>
						<th><span>redeemcredits</span></th>
						<th className="content">Redeem 15,000 credits for 1 redeem</th>
					</tr>
					<tr>
						<th><span>redeemspawn</span></th>
						<th className="content">Spawn a Pokémon for 1 redeem</th>
					</tr>
					<tr>
						<th><span>shop</span></th>
						<th className="content">View the items you can buy from the shop</th>
					</tr>
				</tbody>
			</table>
			<table>
				<tbody className="text-white">
					<div className="table-header">
						<h4>Market</h4>
					</div>
					<tr>
						<th><span>market</span></th>
						<th className="content">View/Manage/Buy/Find Markets!</th>
					</tr>
					<tr>
						<th><span>market search</span></th>
						<th className="content">Search for a pokemon on the market!</th>
					</tr>
					<tr>
						<th><span>market list</span></th>
						<th className="content">Add a pokemon to the market!</th>
					</tr>
					<tr>
						<th><span>market listings</span></th>
						<th className="content">View all the market listings!</th>
					</tr>
					<tr>
						<th><span>market buy</span></th>
						<th className="content">Buy a market pokemon!</th>
					</tr>
					<tr>
						<th><span>market view</span></th>
						<th className="content">View a market pokemon!</th>
					</tr>
					<tr>
						<th><span>market end</span></th>
						<th className="content">Remove a pokemon from the market!</th>
					</tr>
				</tbody>
			</table>
			<table>
				<tbody className="text-white">
					<div className="table-header">
						<h4>Auction</h4>
					</div>
					<tr>
						<th><span>auction</span></th>
						<th className="content">View/Manage/Buy/Find Auctions!</th>
					</tr>
					<tr>
						<th><span>auction search</span></th>
						<th className="content">Search for a pokemon on the auction!</th>
					</tr>
					<tr>
						<th><span>auction list</span></th>
						<th className="content">Add a pokemon to the auction!</th>
					</tr>
					<tr>
						<th><span>auction listings</span></th>
						<th className="content">View all the auction listings!</th>
					</tr>
					<tr>
						<th><span>auction buy</span></th>
						<th className="content">Buy a auction pokemon!</th>
					</tr>
					<tr>
						<th><span>auction view</span></th>
						<th className="content">View a auction pokemon!</th>
					</tr>
					<tr>
						<th><span>auction end</span></th>
						<th className="content">Remove a pokemon from the auction!</th>
					</tr>
				</tbody>
			</table>
			<table>
				<tbody className="text-white">
					<div className="table-header">
						<h4>Settings</h4>
					</div>
					<tr>
						<th><span>redirect</span></th>
						<th className="content">Redirect all Pokémon spaws to 1 channel</th>
					</tr>
					<tr>
						<th><span>setprefix</span></th>
						<th className="content">Set a custom prefix</th>
					</tr>
					<tr>
						<th><span>settings</span></th>
						<th className="content">Check your settings</th>
					</tr>
				</tbody>
			</table>
		</div>
    );
  };
};

export default Commands;
