import React from 'react';
import Commands from './commands';

import Language from '../../languages.json';

interface AppProps {
	language: string;
};

interface AppState {
};

class Home extends React.Component<AppProps, AppState> {
	language: any;
	
  render(){
		this.language = Language[this.props.language].translation;
		return (
			<div className="container">
				<section>
					<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6 order-lg-2">
						<div className="p-5">
							<img className="img-fluid feature-image" src="/image/start.webp" alt="" />
						</div>
						</div>
						<div className="col-lg-6 order-lg-1 text-white">
						<div className="p-5">
							<h3 className="display-5">{this.language.text1}</h3>
							<p>{this.language.text2}</p>
						</div>
						</div>
					</div>
					</div>
				</section>

				<div style={{textAlign: 'center'}}>
					<a href="/invite">
						<button className="btn addButton" >{this.language.text3}</button>
					</a>
				</div>

				<section>
					<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
						<div className="p-5">
							<img className="img-fluid feature-image" src="/image/spawn.webp" alt="" />
						</div>
						</div>
						<div className="col-lg-6 text-white">
						<div className="p-5">
							<h2 className="display-5">{this.language.text4}</h2>
							<p>{this.language.text5}</p>
						</div>
						</div>
					</div>
					</div>
				</section>

				<section>
					<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6 order-lg-2">
						<div className="p-5">
							<img className="img-fluid feature-image" src="/image/hunt.webp" alt="" />
						</div>
						</div>
						<div className="col-lg-6 order-lg-1 text-white">
						<div className="p-5">
							<h3 className="display-5">{this.language.text6}</h3>
							<p>{this.language.text7}</p>
						</div>
						</div>
					</div>
					</div>
				</section>

				<section>
					<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
						<div className="p-5">
							<img className="img-fluid feature-image" src="/image/quest.webp" alt="" />
						</div>
						</div>
						<div className="col-lg-6 text-white">
						<div className="p-5">
							<h2 className="display-5">{this.language.text8}</h2>
							<p>{this.language.text9}</p>
						</div>
						</div>
					</div>
					</div>
				</section>

				<section>
					<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6 order-lg-2">
						<div className="p-5">
							<img className="img-fluid feature-image" src="/image/market.webp" alt="" />
						</div>
						</div>
						<div className="col-lg-6 order-lg-1 text-white">
						<div className="p-5">
							<h3 className="display-5">{this.language.text10}</h3>
							<p>{this.language.text11}</p>
						</div>
						</div>
					</div>
					</div>
				</section>

				<section>
					<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
						<div className="p-5">
							<img className="img-fluid feature-image" src="/image/auction.webp" alt="" />
						</div>
						</div>
						<div className="col-lg-6 text-white">
						<div className="p-5">
							<h2 className="display-5">{this.language.text12}</h2>
							<p>{this.language.text13}</p>
						</div>
						</div>
					</div>
					</div>
				</section>

				<section>
					<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6 order-lg-2">
						<div className="p-5">
							<img className="img-fluid feature-image" src="/image/trade.webp" alt="" />
						</div>
						</div>
						<div className="col-lg-6 order-lg-1 text-white">
						<div className="p-5">
							<h3 className="display-5">{this.language.text14}</h3>
							<p>{this.language.text15}</p>
						</div>
						</div>
					</div>
					</div>
				</section>

				<section>
					<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
						<div className="p-5">
							<img className="img-fluid feature-image" src="/image/settings.webp" alt="" />
						</div>
						</div>
						<div className="col-lg-6 text-white">
						<div className="p-5">
							<h2 className="display-5">{this.language.text16}</h2>
							<p>{this.language.text17}</p>
						</div>
						</div>
					</div>
					</div>
				</section>
			</div>
		);
  };
};

export { Home, Commands };
