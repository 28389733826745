import React from 'react';
import { Link } from "react-router-dom";
import LogIn from "./dashboard/logIn";
import { Navbar } from 'react-bootstrap';
import Language from '../languages.json';

interface AppProps {
    dashboard: boolean | null;
    language: string;
}

interface AppState {
    loginStatus: string,
    redirect: any;
}


class Header extends React.Component<AppProps, AppState> {
    userData: any;
    profilePicture: string;
    logIn: () => void;
    updateUserData: () => void;
    language: any;
    dashboard: boolean | null;

    constructor(props){
        super(props);
        this.state = {
            loginStatus: '',
            redirect: <div />
        };
        this.language = Language[this.props.language].translation;
        this.dashboard = props.dashboard;
        this.userData = false;
        this.profilePicture = '';
        this.updateUserData = () => {
            this.userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData') || "{}"): false;
            if (this.userData.avatar==null){
                this.profilePicture = `https://cdn.discordapp.com/embed/avatars/${parseInt(this.userData.discriminator) % 5}.png`;
            } else {
                this.profilePicture = `https://cdn.discordapp.com/avatars/${this.userData.id}/${this.userData.avatar}.webp`;
            };
        };
        this.updateUserData();
        this.logIn = async () => {
            this.setState({loginStatus: 'Logging in...'}, async () => {
                await LogIn(this);
            });   
        };
    };
    
    shouldComponentUpdate(){
        this.updateUserData();
        return true;
    };

    render() {
        this.language = Language[this.props.language].translation;
        return (
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" style={{paddingLeft: '5px',paddingRight: '5px'}}>
                <Navbar.Brand href="#home"><img src="/image/logo.webp" alt="" width="30" height="30" className="d-inline-block align-text-top" /> <span className="logo-text">PokéHunt</span></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    


                        { this.dashboard ? 
                        <ul className="navbar-nav" style={{ fontFamily: 'Nunito, sansSerif' }}>
                            <li className="nav-item invite">
                                <Link className="nav-link invite-text" to="/">-- Go back home</Link>
                            </li>
                        </ul>
                        :
                        <ul className="navbar-nav" style={{ fontFamily: 'Nunito, sansSerif' }}>
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to="/">{this.language.Home}</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to="/commands">{this.language.Commands}</Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/support">{this.language.Support}</a>
                            </li>
                            <li className="nav-item invite">
                                <a className="nav-link invite-text" href="/invite">{this.language.Invite}</a>
                            </li>
                         </ul>
                         }
                        


                    <div className=" navbar-collapse justify-content-end" >
                        
                        <ul className="navbar-nav" style={{ fontFamily: 'Nunito, sansSerif' }}>
                            {this.userData === false  ? //If not logged in do
                        <li className="nav-item invite">
                            <div className="nav-link invite-text" onClick={this.logIn}>{this.state.loginStatus || 'Dashboard Login'}</div>
                        </li>
                        : // if logged in do
                        <li>
                            <ul className="navbar-nav">
                                <li className="nav-item"  style={{margin: '5px'}}>
                                    <img alt="Users Profile" style={{width: '50px', height: '50px', borderRadius: '50px', border: '1px solid #bd3737'}} src={this.profilePicture} />
                                </li>
                                <li className="nav-item"  style={{margin: '5px'}}>
                                    <h6 className="nav-link" style={{color: 'lightgray'}}>{this.userData.username + "#" + this.userData.discriminator}</h6>
                                </li>
                                
                                
                                <li className="nav-item invite"  style={{margin: '5px'}}>
                                    <Link className="nav-link invite-text" to="/dashboard">Dashboard</Link>
                                </li>

                                <li className="nav-item "  style={{margin: '5px'}}>
                                    <Link className="nav-link invite-text" to="/logout">Logout</Link>
                                </li>
                            </ul>
                        </li>
                        
    }

                        </ul>
                    </div>
                </Navbar.Collapse>
                {this.state.redirect}
            </Navbar>

        );
    };
};

export default Header;