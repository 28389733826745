import React from 'react';
import Languages from './languages.json';

interface AppProps {
    setLanguage: ( newLanguage: string ) => void;
};

interface AppState {
  language: string;
}

export default class Footer extends React.Component<AppProps, AppState> {
    setLanguage: ( newLanguage: string ) => void;
    setNewLanguage: (e: any) => void;

    constructor(props){
      super(props);
      this.state = {
        language: localStorage.getItem('language') || 'en'
      };
      this.setLanguage = props.setLanguage;
      this.setNewLanguage = (event) => {
        this.setState({language: event.target.value}, () => {
          this.setLanguage(event.target.value);
        });
      };
    };
    render(){
      return (
        <footer className="page-footer bottom font-small black pt-4" style={{background:"#3a3a3a", color:"white"}}>

        <div className="container text-center text-md-left">
        
          <div className="row">
            
          <div className="col-md-6 mt-md-0 mt-3">
        
        <h5 className="text-uppercase">Found any problems?</h5>
        <p>Please report them to us using the #bug-reports channel on our  <a href="https://pokehunt.xyz/support">discord server</a></p>
        
        </div>
        
        <hr className="clearfix w-100 d-md-none pb-3" />
        
            <div className="col-md-3 mx-auto">
        
              <h5 className="font-weight-bold text-uppercase mt-3 mb-4">leaderboard</h5>
        
              <ul className="list-unstyled">
                <li>
                  <a href="https://pokehunt.xyz/leaderboard/">Home</a>
                </li>
                <li>
                  <a href="https://pokehunt.xyz/leaderboard/pokemon">Pokemon</a>
                </li>
                <li>
                  <a href="https://pokehunt.xyz/leaderboard/credits">Credits</a>
                </li>
                <li>
                  <a href="https://pokehunt.xyz/leaderboard/donations">Donations</a>
                </li>
              </ul>
        
            </div>
        
            <hr className="clearfix w-100 d-md-none" />
        
            <div className="col-md-3 mx-auto">
        
              <h5 className="font-weight-bold text-uppercase mt-3 mb-4">Language</h5>
        
              <ul className="list-unstyled">
                <li>
                <span className="flag-icon flag-icon-gb" />
                    <select onChange={this.setNewLanguage} value={ this.state.language } id="languages" name="languages">
                    { Object.keys(Languages).map(e => <option value={e}>{Languages[e].display_name}</option>) }
                    </select>
                    
                </li>
              </ul>
        
            </div>
        
            
        
          </div>
        
        </div>
        
        <div className="footer-copyright text-center py-3">© 2020 Copyright:
                    <a href="https://pokehunt.xyz"> PokeHunt </a>
                    made with ❤️ ➕ 💻 ➕ ⚛️ by <a href="https://freddie.pw/"> freddie.pw </a>
                    in the UK
                    </div> 
        
        
        </footer>        
      );
  };
}; 