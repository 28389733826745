import React from 'react';
import { Link } from 'react-router-dom';



interface AppProps {
}

interface AppState {
    profileUrl: string;
}


class Home extends React.Component<AppProps, AppState> {
    userData: any;
    timeZone: string;

    constructor(props){
        super(props);
        this.userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData') || "{}"): false;
        const curHr = new Date().getHours();
        if (curHr < 12) {
            this.timeZone = 'morning';
        } else if (curHr < 18) {
            this.timeZone = 'afternoon';
        } else {
            this.timeZone = 'evening';
        }
        this.state = {
            profileUrl: ''
        }
    };

  render(){
    return (
        <div className="container text-white">
            <h1 >Good {this.timeZone} {this.userData.username}, what do you want to do today?</h1>
            <div className="block">
                <Link className="row" to = "/dashboard/market">Market</Link>
                <Link className="row" to ="/dashboard/auction">Auction</Link>
                <Link className="row" to ="/dashboard/trade">Trade</Link>
                <Link className="row" to ="/dashboard/pokemon">Pokemon Collection</Link>
            </div>

            <img alt="Profile Card" className='profile-card' src={localStorage.getItem('profileCard') || ''} />
		</div>
    );
  };
};

export default Home;
