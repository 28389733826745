import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import './App.css';
import Header from './elements/header';
import Footer from './footer';

import Language from './languages.json';

import * as HomePage from './elements/home-page/index';

import Dashboard from './elements/dashboard/index';

interface AppProps {
};

interface AppState {
  language: string;
};

class App extends React.Component<AppProps, AppState> {
  setLanguage: (newLanguage: string) => void;

  constructor(props){
    super(props);
    this.state = {
      language: localStorage.getItem('language') || 'en'
    }
    this.setLanguage = (newLanguage) => {
      this.setState({language: newLanguage}, () => {
        localStorage.setItem('language', newLanguage);
      });
      
    };
  };

  componentWillMount(){
    if (!Object.keys(Language).includes(this.state.language)){
      this.setLanguage('en');
    }
  }

  render(){
    return (
      <html >
        <head>
      <script src="https://cdn.socket.io/4.0.0/socket.io.min.js" integrity="sha384-DkkWv9oJFWLIydBXXjkBWnG1/fuVhw8YPBq37uvvD6WSYRFRqr21eY5Dg9ZhmWdy" crossOrigin="anonymous"></script>
      <script src="https://unpkg.com/react/umd/react.production.min.js" crossOrigin="anonymous"></script>
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta1/dist/js/bootstrap.bundle.min.js" integrity="sha384-ygbV9kiqUc6oa4msXn9868pTtWMgiQaeYH7/t7LECLbyPA2x65Kgf80OJFdroafW" crossOrigin="anonymous"></script>
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-giJF6kkoqNQ00vy+HMDP7azOuL0xtbfIcaT9wjKHr8RbDVddVHyTfAAsrekwKmP1" crossOrigin="anonymous" />
      <link href="/css/style.css" rel="stylesheet" type="text/css" />
      <title>PokéHunt</title>
	</head>
  <body className="bg-dark">
        <NotificationContainer />

        <Router>
          <Switch>

              <Route path="/" exact >
                <Header dashboard={false} language={this.state.language} />
                <HomePage.Home language={this.state.language} />
              </Route>

              <Route path="/commands" exact >
                <Header dashboard={false} language={this.state.language} />
                <HomePage.Commands />
              </Route>

              <Route path="/dashboard*" >
                <Dashboard language={this.state.language} />
              </Route>


              <Route path="/support" >
                <Redirect to="https://discord.gg"/>
              </Route>

              <Route path="/logout" exact >
                <LogOut />
              </Route>

          </Switch>
        </Router>
        </body>
        <Footer setLanguage={this.setLanguage}/>
      </html>
    );
  };
};

class LogOut extends React.Component {
  constructor(props){
    super(props);
    localStorage.clear();
  };
  render(){
    return <Redirect to="/" />;
  }
};
class WiP extends React.Component {
  constructor(props){
    super(props);
    NotificationManager.error('Sorry this is a WIP!');
  };
  render(){
    return <Redirect to="/" />;
  }
};
export default App;

