import React from 'react';
import { URLs, logger } from '../../utils';
import { NotificationManager } from 'react-notifications';
import { Redirect } from "react-router-dom";

async function logIn(self: React.Component){
    return new Promise(async (res,rej) => {
        logger('Starting login sequence');
        const loginUrls = await fetch(`${URLs.API}/login`); //fetch the login and reportTo links
        const loginDataJson = await loginUrls.json();
        logger('Trying to open login window...');
        let externalWindow = window.open(loginDataJson.loginURL, '', 'width=600,height=1000,left=200,top=200'); //open login link in new window
        let timer;
        let closedForLogin = false; //keep track of the reason the window has closed
        if (externalWindow) timer = setInterval(async (parent = self) => {  //repeat until window has closed
            if (externalWindow?.closed && !closedForLogin){   //if the window has been closed by the user do
                clearInterval(timer);
                parent.setState({loginStatus: 'Dashboard Login'}, () => {
                    NotificationManager.error('Reason: User closed window', 'Login canceled') //send a error message 
                    rej();
                });
            } else if (closedForLogin){ //if the window has been marked as closed kill the interval
                clearInterval(timer);
            }
        }, 500); //
        let authToken = await fetch(loginDataJson.reportTo); // send request to 
        let authTokenJson = await authToken.json();
        closedForLogin = true; //ensure the listeners know the code is closing the window
        externalWindow?.close(); //close the window 

        localStorage.setItem('Authentication', authTokenJson.authToken); // save authentication token
        localStorage.setItem('userData', JSON.stringify(authTokenJson.userData)); // save user data (kinda like a cache)

        const profileImageBuffer = await fetch(`${URLs.API}/user/profileCard`, { headers:{authentication: localStorage.getItem('Authentication') || ''}});
        const profileImageArrayBuffer = await profileImageBuffer.arrayBuffer();
        const profileImageBase64Raw = Buffer.from(profileImageArrayBuffer).toString('base64');
        const profileImageBase64 = `data:image/png;base64,${profileImageBase64Raw}`;
        localStorage.setItem('profileCard', profileImageBase64);    

        self.setState({loginStatus: 'logged in'}, () => {
            NotificationManager.success(`Welcome back ${authTokenJson.userData.username}`, 'Logged in!') //send a welcome message 
            NotificationManager.info('Click me to go to your dashboard!', '', 7000, (self1 = self) => {
                self1.setState({redirect: <Redirect to="/dashboard" />});
            }) //send a welcome message 
            res(true);
        });        
    });
};


export default logIn;