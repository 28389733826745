import { w3cwebsocket as W3CWebSocket } from "websocket";
import { EventEmitter } from "events";

const URLs = {
    GATEWAY: 'wss://gateway.pokehunt.xyz',
    API: 'https://v4api.pokehunt.xyz'
};
function logger(message: string){
    console.log(`[POKÉHUNT] ${message}`);
};
const createUUID = (length = 5, count = 4, joiner = '-', chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ123456789" ) => {
    const fin = new Array();
    for (let i = 0; i <= count; i++) {
        let t = new String()
        for (let i = 0; i <= length; i++) {
            t += (chars[Math.floor(Math.random() * chars.length)])
        };
        fin.push(t);
    };
    return fin.join(joiner);
}; 
class WsRequest {
    WebSocketCon: W3CWebSocket;
    messageEvent: EventEmitter;

    constructor(WebSocketCon: W3CWebSocket, messageEvent: EventEmitter){
        this.WebSocketCon = WebSocketCon;
        this.messageEvent = messageEvent;
    }

    request(command: string, params: any){
        return new Promise(() => {

        });
    };
}
export { URLs, logger, WsRequest, createUUID };
